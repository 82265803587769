<template>
    <!--    todo 暂不实现创建机构组时候批量添加机构-->
    <!--    todo 业务变更, 创建集团后,默认创建总部电商机构组-->
    <!--    todo 业务变更, 创建机构组后,在机构组中创建机构,不能单独创建机构 -->
    <div class="GroupCreate">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form :model="form" :rules="rules" ref="form" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="组名称" prop="name">
                            <el-input v-model="form.name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="组类型">
                            <el-select v-model="form.deptType">
                                <el-option :value="3" label="门店" />
                                <el-option :value="2" label="配送中心" />
                                <el-option :value="1" label="电商总部" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="form.remark" />
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item>
                        <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                    </el-form-item>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import EfRemark from 'components/EfRemark';

export default {
    name: 'GroupCreate',
    components: { EfRemark },
    data() {
        return {
            form: {
                deptType: 3,
                name: '',
                remark: '',
            },
            rules: {
                name: [{ required: true, max: 20, message: '机构组名称必填', trigger: 'blur' }],
                remark: [{ required: true, max: 200, message: '备注必填且不超过200个字', trigger: 'blur' }],
            },
            url: '/system/deptGroup/createExtend',
        };
    },
    methods: {
        handleSave() {
            const group = this.form;
            if (!group.name) {
                this.$alert('请填写组名称', '提示', { type: 'warning' });
                return;
            }
            if (!group.deptType) {
                this.$alert('请选择组类型', '提示', { type: 'warning' });
                return;
            }
            if (!group.remark) {
                this.$alert('请填写备注', '提示', { type: 'warning' });
                return;
            }
            UrlUtils.PostRemote(this, this.url, this.form);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
